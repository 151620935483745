import {
  ExternalIcon,
  Icon,
  IconSize,
  PopoverContentWrapper,
  PopoverIconWrapper,
  SelectBox,
  SelectValueProps,
  StyledTd,
  StyledTr,
  Text,
  TextSize,
  TextTag,
  WhiteSpace,
} from '@yarmill/components';
import moment from 'moment';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { EllipsisButton } from '../components/ellipsis-button';
import { Popover } from '../components/popover';
import { PopoverItem } from '../components/popover/popover-item';
import {
  trackChangeUserRights,
  trackRemoveUserFromGroupCancelClick,
  trackRemoveUserFromGroupClick,
  trackRemoveUserFromGroupConfirmClick,
  trackShowUserDetails,
} from '../google-analytics/utils';
import { Permission } from '../permissions/types';
import { Avatar } from '../profile/avatar';
import { UserInGroupValidity } from './types';
import { useRootStore } from '../app/root-store-context';
import { Tippy } from '../components/tippy/tippy';
import { UserStore } from '../users/mobx/user-store';
import { GroupStore } from './mobx/group-store';
import { useCurrentUserStore, useUsersStore } from '../users/hooks';
import { Instance as TippyInstance } from 'tippy.js';
import { subscribeUserToCalendar } from '../google-calendar/utils';
import { toast } from '../components/toast-message';
import { observer } from 'mobx-react-lite';
import { UserAccountState } from '../users/types';
import { RoleTag } from '../components/role-tag';

export interface UserRowProps {
  group: GroupStore;
  user: UserStore;
  permission: Permission;
  validityDate?: UserInGroupValidity;
  isHistorical?: boolean;
}

export const UserRow = observer(function UserRow(
  props: UserRowProps
): JSX.Element {
  const { user, permission, validityDate, isHistorical, group } = props;
  const intl = useIntl();
  const rootStore = useRootStore();
  const currentUser = useCurrentUserStore();
  const usersStore = useUsersStore();

  const showGoogleCalendar =
    rootStore.configStore.availableExternalServices.includes('google');
  const permissionOptions = [
    {
      label: intl.formatMessage({ id: 'settings.groups.permissions.read' }),
      value: 1,
    },
    {
      label: intl.formatMessage({ id: 'settings.groups.permissions.write' }),
      value: 2,
    },
  ];

  const getSelectPermissionValue =
    String(permission.Permission) === 'read'
      ? permissionOptions[0]
      : permissionOptions[1];

  const removeUserFromGroup = async (): Promise<void> => {
    trackRemoveUserFromGroupClick(user.displayName);
    const message = intl.formatMessage(
      { id: 'settings.users.discardUserConfirm' },
      { user: user.displayName, group: group.name }
    );
    if (window.confirm(message)) {
      trackRemoveUserFromGroupConfirmClick(user.displayName);
      const success = await group.removeUser(user);
      if (user.id === currentUser.id) {
        void rootStore.currentUserStore.loadPermissions();
      }
      void group.loadHistoricalAthletes();

      if (success) {
        toast(`toast.success.removeUserFromGroup`, 'success', {
          user: user.displayName,
          group: group.name,
        });
      } else {
        toast(`toast.error.removeUserFromGroup`, 'error', {
          user: user.displayName,
          group: group.name,
        });
      }
    } else {
      trackRemoveUserFromGroupCancelClick(user.displayName);
    }
  };

  const updatePermission = async (value: SelectValueProps): Promise<void> => {
    trackChangeUserRights(user.displayName);
    await group.updateMemberPermissions(
      user.id,
      value.value === 1 ? 'read' : 'write'
    );

    if (user.id === currentUser.id) {
      await currentUser.loadPermissions();
    }
  };

  const subscribeToUsersCalendar = async (
    tippy: TippyInstance
  ): Promise<void> => {
    const googleCalendarService = rootStore.googleCalendarService;
    tippy.hide();

    await subscribeUserToCalendar(googleCalendarService, user.internalUser);
  };

  const handleImpersonateUser = async (): Promise<void> => {
    try {
      await usersStore.impersonateUser(user.id);
      window.location.href = '/';
    } catch (e) {
      toast('toast.error.impersonateUser', 'error', {
        user: user.displayName,
      });
    }
  };

  const popoverContent: ((tippy: TippyInstance) => JSX.Element)[] = [
    rootStore.currentUserStore.isAllowedTo('settings.users.detail') &&
      (() => (
        <PopoverItem
          key="user-detail"
          to={
            currentUser && currentUser.id === user.id
              ? '/settings/personal'
              : `/settings/users/${user.id}`
          }
          onClick={() => trackShowUserDetails(user.displayName)}
        >
          <PopoverIconWrapper>
            <Icon size={IconSize.s20}>
              <ExternalIcon name="UserCircle" />
            </Icon>
          </PopoverIconWrapper>
          <PopoverContentWrapper>
            <strong>
              <FormattedMessage id="settings.users.memberDetail" />
            </strong>
            <div>
              <FormattedMessage id="settings.users.memberDetailHelp" />
            </div>
          </PopoverContentWrapper>
        </PopoverItem>
      )),
    user.isAthlete &&
      showGoogleCalendar &&
      ((tippy: TippyInstance) => (
        <Tippy
          key="subscribe-calendar"
          isEnabled={!currentUser.data?.ExternalServices.google}
          tooltipContent="settings.users.subscribeCalendar.disabled"
          noWrapper
        >
          <PopoverItem
            onClick={
              currentUser.data?.ExternalServices.google
                ? () => subscribeToUsersCalendar(tippy)
                : undefined
            }
            appearance={
              currentUser.data?.ExternalServices.google ? 'default' : 'disabled'
            }
          >
            <PopoverIconWrapper>
              <Icon size={IconSize.s20}>
                <ExternalIcon name="Calendar" />
              </Icon>
            </PopoverIconWrapper>
            <PopoverContentWrapper>
              <strong>
                <FormattedMessage id="settings.users.subscribeCalendar" />
              </strong>
              <div>
                <FormattedMessage id="settings.users.subscribeCalendarHelp" />
              </div>
            </PopoverContentWrapper>
          </PopoverItem>
        </Tippy>
      )),
    currentUser.isAdmin &&
      !isHistorical &&
      (() => (
        <PopoverItem
          key="discard-user"
          appearance="danger"
          onClick={removeUserFromGroup}
        >
          <PopoverIconWrapper>
            <Icon size={IconSize.s20}>
              <ExternalIcon name="CircleX" />
            </Icon>
          </PopoverIconWrapper>
          <PopoverContentWrapper>
            <strong>
              <FormattedMessage id="settings.users.discardUser" />
            </strong>
            <div>
              <FormattedMessage id="settings.users.discardUserHelp" />
            </div>
          </PopoverContentWrapper>
        </PopoverItem>
      )),
    currentUser.isAllowedTo('app.impersonate') &&
      user.internalUser.AccountStateId === UserAccountState.ACTIVE &&
      ((tippy: TippyInstance) => (
        <PopoverItem
          key="impersonate"
          onClick={async () => {
            void handleImpersonateUser();
            tippy.hide();
          }}
        >
          <PopoverIconWrapper>
            <Icon size={IconSize.s20}>
              <ExternalIcon name="Users" />
            </Icon>
          </PopoverIconWrapper>
          <PopoverContentWrapper>
            <strong>
              <FormattedMessage
                id="settings.users.impersonateUser"
                values={{
                  user: user.displayName,
                }}
              />
            </strong>
            <div>
              <FormattedMessage
                id="settings.users.impersonateUserHelp"
                values={{
                  user: user.displayName,
                }}
              />
            </div>
          </PopoverContentWrapper>
        </PopoverItem>
      )),
  ].filter(Boolean) as ((tippy: TippyInstance) => JSX.Element)[];

  return (
    <StyledTr className="group-user-row">
      <StyledTd className="noborder user-icon">
        <Avatar id={user.avatar} alt={user.displayName} />
      </StyledTd>
      <StyledTd align="left">
        <Text
          size={TextSize.s12}
          tag={TextTag.div}
          bold
          whiteSpace={WhiteSpace.noWrap}
        >
          {user.displayName}
        </Text>
        <Text
          size={TextSize.s12}
          tag={TextTag.div}
          whiteSpace={WhiteSpace.noWrap}
        >
          {user.internalUser.Email}
        </Text>
      </StyledTd>
      <StyledTd>
        <RoleTag role={user.internalUser.Role} />
      </StyledTd>
      {currentUser.isAdmin && !user.isAthlete && !isHistorical ? (
        <StyledTd minWidth="90px">
          <SelectBox
            id="permission"
            label=""
            options={permissionOptions}
            isSearchable={false}
            value={getSelectPermissionValue}
            onChange={updatePermission}
            noSeparator
            longList
            noExtraLabel
            noError
            noLabel
            maxMenuHeight={350}
          />
        </StyledTd>
      ) : (
        <StyledTd>
          {!user.isAthlete && (
            <Text size={TextSize.s12} whiteSpace={WhiteSpace.noWrap}>
              <FormattedMessage
                id={`settings.groups.permissions.${permission.Permission}`}
              />
            </Text>
          )}
        </StyledTd>
      )}

      <StyledTd>
        <Text size={TextSize.s12} whiteSpace={WhiteSpace.noWrap}>
          <FormattedDate
            value={
              validityDate
                ? moment(validityDate.ValidFrom).toDate()
                : moment(user.internalUser.MemberSince).toDate()
            }
            day="numeric"
            month="numeric"
            year="numeric"
          />
        </Text>
      </StyledTd>
      <StyledTd>
        {validityDate && validityDate.ValidTo && (
          <Text size={TextSize.s12} whiteSpace={WhiteSpace.noWrap}>
            <FormattedDate
              value={moment(validityDate.ValidTo).toDate()}
              day="numeric"
              month="numeric"
              year="numeric"
            />
          </Text>
        )}
      </StyledTd>
      <StyledTd>
        {popoverContent.length > 0 && (
          <Popover
            content={tippy => <>{popoverContent.map(item => item(tippy))}</>}
          >
            <EllipsisButton />
          </Popover>
        )}
      </StyledTd>
    </StyledTr>
  );
});
