import {
  IObservableArray,
  ObservableMap,
  action,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import { ActivityMask } from '../activities/types';
import { AsyncStatus, RequestStore } from '../api/mobx/request-store';
import { RootStore } from '../app/mobx/root-store';
import { AttendancePhase } from '../attendance/types';
import { GoalsAttributes } from '../diary/types';
import { InitialConfigError } from '../error/initial-config-error';
import { EvidenceModuleConfiguration } from '../evidence/types';
import { ExternalLink } from '../header/types';
import { Locale } from '../intl/types';
import { getDefaultAvailableLanguages } from '../intl/utils';
import { OkrExportTemplate } from '../okrs/types';
import { AdditionalRegistrationRequirement } from '../register/types';
import { SeasonViewType } from '../seasons/types';
import { UserRole } from '../users/types';
import { getGlobalConfig } from './api/get-global-config';
import {
  DataService,
  GlobalConfigOption,
  HeaderNavigationConfigItem,
} from './types';

const MODULE_SELECTOR = 'fe.module.';
const MODULE_REGEX = new RegExp(MODULE_SELECTOR);

export class ConfigStore {
  private readonly rootStore: RootStore;
  private request: RequestStore<GlobalConfigOption[]> | null = null;

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  @observable
  public appName: string | null = null;
  @observable
  public logo: string | null = null;
  @observable
  public logoSmall: string | null = null;
  @observable
  public seasonView: SeasonViewType = 'month';
  @observable
  public weekViewEnabled: boolean = true;
  @observable
  public goalsViewEnabled: boolean = false;
  @observable
  public seasonEditable: boolean = true;
  @observable
  public adminEmail: string = '';
  @observable
  public adminName: string = '';
  @observable
  public copyPlanToReality: boolean = false;
  @observable
  public copyPlanToRealityValidDays: number = 0;
  @observable
  public defaultLanguage: Locale = 'cs';
  @observable
  public attendanceCopyPlanToReality: boolean = false;
  @observable
  public availableExternalServices: string[] = [];
  @observable
  public instructions: boolean = false;
  @observable
  public tipsAndTricks: boolean = false;
  @observable
  public tipsAndTricksUrl: string = '';
  @observable
  public showGoalPlaceholders: boolean = false;
  @observable
  public goalsAttributes: {
    reality: GoalsAttributes;
    plan: GoalsAttributes;
  } | null = null;
  @observable
  public avatars: ObservableMap<string, string> = new ObservableMap();
  @observable
  public diaryBackfill: number = -1;
  @observable
  public diaryBackfillScope: UserRole[] = [];
  @observable
  public idleTimeout: number = 8; // hours
  @observable
  public attendancePhases: AttendancePhase[] = [];
  @observable
  public fileUploadUrl: string =
    'https://filestorage.ms.yarmill.com/api/upload';
  @observable
  public evidenceModuleConfigurations: EvidenceModuleConfiguration[] = [];
  @observable
  public reportingDefUrl: string = '/api/reporting/def-v2';
  @observable
  public reportingDataUrl: string = '/api/reporting';
  @observable
  public activityMasks: ActivityMask[] = [];
  @observable
  public seasonGoalsViewEnabled: boolean = false;
  @observable
  public headerNavigationConfig: HeaderNavigationConfigItem[] | null = null;
  @observable
  public yollandaUrl: string | null = null;
  @observable
  public availableLanguages: Locale[] = [];
  @observable
  public additionalRegistrationRequirements: AdditionalRegistrationRequirement[] =
    [];
  @observable
  public yollandaCopilotEnabled: boolean = false;

  @observable
  public dataServices: DataService[] = [];

  @observable
  public okrExportTemplates: OkrExportTemplate[] = [];

  @observable
  public okrInstructionsUrl: string | null = null;

  @observable
  public externalLinks: ExternalLink[] = [];

  @observable
  public modules = new Map();

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  public async loadConfig(): Promise<void> {
    this._status = AsyncStatus.pending;
    this.request = this.rootStore.requestsStore.createRequest(() =>
      getGlobalConfig()
    );
    const response = await this.request.getResponse();
    if (response) {
      await runInAction(async () => {
        this.setConfig(response);

        if (this.modules.get('announcement')?.length) {
          const intlPromise = new Promise<void>(resolve => {
            when(
              () => this.rootStore.intlStore.version === 'api',
              () => {
                this._status = AsyncStatus.resolved;
                resolve();
              }
            );
          });
          await intlPromise;
        } else {
          this._status = AsyncStatus.resolved;
        }
      });
    } else {
      throw new InitialConfigError(
        'Unable to load global config',
        this.request.error
      );
    }
  }

  @action
  public setConfig(options: GlobalConfigOption[]): void {
    options.forEach(option => {
      switch (option.Code) {
        case 'fe.instance.name':
          this.appName = option.Value;
          break;
        case 'fe.instance.logo':
          this.logo = option.Value;
          break;
        case 'fe.instance.logo.small':
          this.logoSmall = option.Value;
          break;
        case 'ytd.seasonView':
          this.seasonView = option.Value;
          break;
        case 'ytd.plan.weekViewEnabled':
          this.weekViewEnabled = option.Value;
          break;
        case 'ytd.plan.goalsViewEnabled':
          this.goalsViewEnabled = option.Value;
          break;
        case 'ytd.reality.seasonEditable':
          this.seasonEditable = option.Value;
          break;
        case 'ytd.instance.adminEmail':
          this.adminEmail = option.Value;
          break;
        case 'ytd.instance.adminName':
          this.adminName = option.Value;
          break;
        case 'ytd.module.copyPlanToReality':
          this.copyPlanToReality = option.Value;
          break;
        case 'ytd.module.copyPlanToReality.daysValid':
          this.copyPlanToRealityValidDays = option.Value;
          break;
        case 'fe.defaultLanguage':
          this.defaultLanguage = option.Value;
          break;
        case 'ytd.module.attendance.copyPlanToReality':
          this.attendanceCopyPlanToReality = option.Value;
          break;
        case 'ytd.module.externalServices.enabled':
          this.availableExternalServices = option.Value;
          break;
        case 'ytd.goals.attributeIds':
          this.goalsAttributes = option.Value;
          break;
        case 'ytd.goals.showGoalPlaceholders':
          this.showGoalPlaceholders = option.Value;
          break;
        case 'fe.avatars.list': {
          const obj = option.Value;
          this.avatars.replace(obj);
          break;
        }
        case 'ytd.diary.backfill':
          this.diaryBackfill = option.Value;
          break;
        case 'ytd.diary.backfillScope':
          this.diaryBackfillScope = option.Value;
          break;
        case 'fe.general.idleUserTimeout':
          this.idleTimeout = option.Value;
          break;
        case 'ytd.module.attendance.phases':
          this.attendancePhases = option.Value;
          break;
        case 'ytd.services.filestorage.upload.url':
          this.fileUploadUrl = option.Value;
          break;
        case 'ytd.evidence.moduleDefinitions':
          this.evidenceModuleConfigurations = option.Value;
          break;
        case 'ytd.reporting.definitionUrl':
          this.reportingDefUrl = option.Value;
          break;
        case 'ytd.reporting.dataUrl':
          this.reportingDataUrl = option.Value;
          break;
        case 'ytd.mask.availableMasks':
          this.activityMasks = option.Value;
          break;
        case 'ytd.plan.seasonGoalsViewEnabled':
          this.seasonGoalsViewEnabled = option.Value;
          break;
        case 'ytd.header.navigationConfig':
          this.headerNavigationConfig = option.Value;
          break;
        case 'ytd.services.yollanda.url':
          this.yollandaUrl = option.Value;
          break;
        case 'fe.availableLanguages':
          (this.availableLanguages as IObservableArray).replace(option.Value);
          break;
        case 'ytd.registration.additionalRequirements':
          this.additionalRegistrationRequirements = option.Value;
          break;
        case 'ytd.services.yollanda.copilot':
          this.yollandaCopilotEnabled = option.Value;
          break;
        case 'ytd.dataServices':
          this.dataServices = option.Value;
          break;
        case 'ytd.module.okr.exportTemplates':
          this.okrExportTemplates = option.Value;
          break;
        case 'ytd.module.okr.instructionsUrl':
          this.okrInstructionsUrl = option.Value;
          break;
        case 'fe.userSection.externalLinks':
          this.externalLinks = option.Value;
          break;
      }
    });

    if (this.availableLanguages.length === 0) {
      this.availableLanguages = getDefaultAvailableLanguages(
        this.defaultLanguage ?? 'cs'
      );
    }

    options
      .filter(option => MODULE_REGEX.test(option.Code))
      .forEach(option => {
        const module = option.Code.replace(MODULE_SELECTOR, '');
        const value = option.Value;
        this.modules.set(module, value);
      });
  }

  public get status(): AsyncStatus {
    return this._status;
  }
}
